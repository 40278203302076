import { queryPopularPosts } from "../api"
import React from 'react'

function compLikes(a, b) {
    if (a.likes < b.likes) {
      return 1
    }
    else if (a.likes > b.likes) {
      return -1
    }
    else {
        return 0
    }
  }

class Trending extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            posts: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.setState({
            posts: this.props.posts.sort((a, b) => compLikes(a, b)).slice(0, 5),
            isLoading: false
        })
        setInterval(() => {
            this.setState({
                posts: this.props.posts.sort((a, b) => compLikes(a, b)).slice(0, 5),
            })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval()
    }

    renderLoadingState = () => {
        return (
            <p>Loading...</p>
        )
    }

    renderPopularPosts = () => {
        let counter = 1
        return (
            <>
                {this.state.posts.map((post) => {
                    return (
                        <div key={post.id} className="w-full flex flex-row font-semibold p-3 items-baseline">
                            <span className="pr-2 text-lg font-black">{counter++}.</span>
                            <span className="truncate">{post.title}</span>
                        </div>
                    )
                })}
            </>
        )
    }

    render() {
        return (
            <div className="bg-whitesmoke m-3 rounded-lg divide-y divide-gray-300 divide-solid">
                <p className="text-xl font-bold pt-3 pb-1 px-3">Trending</p>
                {!this.state.isLoading
                    ? this.renderPopularPosts()
                    : this.renderLoadingState()
                }
            </div>
        )
    }
}

export default Trending