import axios from "axios";

let debug = false
const baseUrl = debug ? "http://127.0.0.1:8787" : "https://app-worker.rnagavar.workers.dev"

function changeLikes(post_id, num_likes) {
    const body = { likes: num_likes }
    let likes = axios.put(baseUrl + "/post/" + post_id + "/likes", body)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            }
            return null
        })

    return likes
}

function queryPopularPosts() {
    let posts = axios.get(baseUrl + "/popularPosts")
        .then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                return null
            }
        })
    
    return posts
}

function queryPosts() {
    let posts = axios.get(baseUrl + "/posts")
        .then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                return null
            }
        })
    
    return posts
}

function createNewPost(title, username, content, encodedImg) {
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    const params = new URLSearchParams()
    params.append('title', title)
    params.append('username', username)
    params.append('content', content)
    params.append('img', encodedImg)
    let post = axios.post(baseUrl + "/posts", params, {headers: headers})
        .then((res) => {
            if (res.status === 200) {
                return res.data
            }
            else {
                return null
            }
        })

    return post
}

export {
    changeLikes,
    queryPosts,
    createNewPost,
    queryPopularPosts
}