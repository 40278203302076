import React from 'react'
import { createNewPost } from '../api';

class CreatePostPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            content: "",
            img: "",
            isExpanded: false,
        };

        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this)
        this.handleImageUpload = this.handleImageUpload.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    toBase64 = (img) => {
        return new Promise((resolve, reject) => {
            const imgReader = new FileReader()
            imgReader.readAsDataURL(img)
            imgReader.onload = () => {
                resolve(imgReader.result)
            }
            imgReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    handleTitleChange(event) {
        this.setState({title: event.target.value});
    }

    handleTextAreaChange(event) {
        this.setState({content: event.target.value});
    }

    async handleImageUpload(event) {
        const img = event.target.files[0];
        const base64 = await this.toBase64(img)
        this.setState({img: base64})
    }

    onSubmit(event) {
        if (this.props.username.length === 0) {
            alert("You must have a username before you can post!")
        }
        else if (this.state.title.length === 0 || this.state.content.length === 0) {
            alert("Title and content must be filled out before posting!")
        }
        else {
            createNewPost(this.state.title, this.props.username, this.state.content, this.state.img)
                .then((post) => {
                    if (post !== null) {
                        this.props.addPost(post)
                    }
                })
            this.handleCancel()
        }
        
        event.preventDefault();
    }

    handleCancel = () => {
        this.setState({isExpanded: false, title: '', content: '', img: ''})
    }

    renderExpandedView = () => {
        return (
            <div className="flex flex-col px-6 py-3">
                <form className="w-full" onSubmit={this.onSubmit}>
                        <label 
                            className="block uppercase tracking-wide 
                                    text-gray-700 text-xs font-bold mb-2" 
                            for="title">
                            Title
                        </label>
                        <input onChange={this.handleTitleChange}
                            className="appearance-none block w-full bg-gray-200 
                                    text-gray-700 border rounded py-3 px-4 mb-3 leading-tight 
                                    focus:outline-none focus:bg-white" 
                            id="title" 
                            type="text" placeholder="Title"/>
                        <label 
                            className="block uppercase tracking-wide 
                                    text-gray-700 text-xs font-bold mb-2" 
                            for="content">
                            Content
                        </label>
                        <label
                            className="mb-4 w-64 flex flex-col items-center px-4 py-6 bg-white rounded-md 
                                shadow-md tracking-wide uppercase border-dashed cursor-pointer
                                hover:bg-dogerblue hover:text-white text-dogerblue-600 ease-linear 
                                transition-all
                                duration-150">
                            <span className="text-base font-semibold leading-normal">{this.state.img ? 'Image Uploaded' : 'Upload Image'}</span>
                            <input onChange={this.handleImageUpload} type="file" className="hidden" accept=".jpeg, .png, .jpg"/>
                            <img className={this.state.img ? 'rounded-lg mt-3' : 'hidden'} src={this.state.img}/>
                        </label>
                        <textarea onChange={this.handleTextAreaChange}
                            class="appearance-none block w-full bg-gray-200 
                                text-gray-700 border rounded py-3 px-4 mb-3 leading-tight 
                                focus:outline-none focus:bg-white" 
                            id="grid-content" 
                            type="text" placeholder="What's on your mind?"/>
                        {/* Cancel button and submit button */}
                        <div className="flex flex-row justify-end mt-3">
                            <button onClick={this.handleCancel} 
                                className="text-red-400 px-4">Cancel</button>
                            <input type="submit" value="Post"
                                className="cursor-pointer bg-dogerblue px-6 py-1 
                                    rounded-full text-white font-semibold"/>
                        </div>
                </form>
            </div>
        )
    }

    renderDefaultView = () => {
        return (
            <div className="flex flex-row p-6">
                <div onClick={() => this.setState({isExpanded: true})}
                    className="bg-whitesmoke rounded-full py-3 w-full cursor-pointer hover:bg-gray-100">
                    <p className="text-gray-500 px-3">What's on your mind?</p>
                </div>
            </div>
        )
    }

    render() {
        console.log(this.state)
        if (this.state.isExpanded) {
            return this.renderExpandedView()
        }
        else {
            return this.renderDefaultView()
        }
    }
  }


export default CreatePostPanel;