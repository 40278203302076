import React from "react"
import { changeLikes } from "../api"

class Post extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            likes: 0,
            isLiked: false,
            timeSincePost: 0
        }
    }

    componentDidMount() {
        this.setState({likes: this.props.data.likes})
        setInterval(() => {
            this.setState({timeSincePost: this.postTimeFromNow()})
        }, 60000)
    }

    componentWillUnmount() {
        clearInterval()
    }

    generateAvatarUrl(username) {
        return `https://ui-avatars.com/api/?background=random&name=${username}&rounded=true`
    }

    handleLikeButton = () => {
        if (this.state.isLiked) {
            var newLikeCount = --this.state.likes
        }
        else {
            var newLikeCount = ++this.state.likes
        }
        var isLiked = !this.state.isLiked
        changeLikes(this.props.data.id, newLikeCount)
        this.setState({likes: newLikeCount, isLiked: isLiked})
    }

    postTimeFromNow = () => {
        var time_diff = this.props.data.post_time - Date.now();
        var hours = Math.abs(time_diff) / 36e5
        if (hours >= 14) {
            return `${Math.floor(hours/7)} d`
        }
        else if (hours < 1) {
            return `${Math.floor(hours*60)} m`
        }
        else {
            return `${Math.floor(hours)} h`
        }
    }

    render() {
        return (
            <div className="flex flex-col p-5 borderborder-gray-100 hover:bg-blue-50">
                <div className="flex flex-row">
                    <div className="flex flex-col items-center justify-center pr-5 ">
                        <img className="h-6 w-6" src={this.generateAvatarUrl(this.props.data.username)}/>
                    </div>
                    <div className="flex flex-col justify-between">
                        <div className="flex flex-row items-baseline">
                            <p className="text-sm">
                                <span className="text-blue-500 font-semibold">
                                    {this.props.data.username}
                                </span>
                                <span className="text-sm text-gray-400 font-semibold px-1">
                                    shared
                                </span>
                                <span className="font-bold pr-1">
                                    {this.props.data.title}
                                </span>
                                <span className="text-gray-400">
                                    &middot; {this.postTimeFromNow()}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center">
                    <div className="flex flex-col h-auto w-auto items-center justify-center object-fill p-5">
                        <img className={this.props.data.img ? 'block rounded-lg' : "hidden rounded-lg"} 
                            alt="post-thumbnail" src={this.props.data.img}/>
                    </div>
                </div>
                <div className="px-3">
                    <div className="flex flex-row">
                        <p className="max-w-lg">{this.props.data.content}</p>
                    </div>
                    <div className="flex flex-row items-baseline">
                        <button onClick={this.handleLikeButton} className="text-pink-600 font-bold pr-2">{this.state.isLiked ? 'Liked' : 'Like'}</button>
                        <p className="text-gray-400">{this.state.likes}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Post