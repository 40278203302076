import React from 'react'
import CreatePostPanel from "./components/CreatePostPanel";
import Post from './components/Post'
import axios from 'axios';
import { queryPosts } from './api';
import Logo from './components/Logo';
import Trending from './components/Trending';

class Feed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      isLoading: true,
      username: "",
      isAuthenticated: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  queryApi = () => {
    queryPosts().then((newPosts) => {
      if (newPosts !== null) {
        this.setState({posts: [...newPosts], isLoading: false})
      }
    })
  }

  componentDidMount() {
    // add posts to state for rendering
    this.queryApi()
    // setInterval(() => this.queryApi(), 30000)
  }

  renderLoadingState() {
    return (
      <section className="min-h-screen flex flex-row justify-center">
        <div className="flex flex-col items-center w-2/5 mt-10">
          <p className="text-blue-500 font-semibold text-lg">
            Loading posts...
          </p>
        </div>
        
      </section>
    )
  }

  handleChange(event) {
    this.setState({username: event.target.value});
  }

  addPost = (post) => {
    const newPosts = [...this.state.posts];
    newPosts.splice(0, 0, post);
    this.setState({ posts: newPosts });
  }

  /* Handles submitting the  */
  onSubmit(event) {
    var authStatus = true
    if (this.state.username.length === 0) {
      alert("Please enter a non-empty string")
      authStatus = false
    }
    this.setState({username: this.state.username, isAuthenticated: authStatus})
    event.preventDefault();
  }

  renderSocialFeed() {
    return (
      <section className="min-h-screen w-screen table table-auto">
        <div className="table-cell w-1/5 align-top border-r-2 border-gray-100">
         <div className="bg-whitesmoke m-3 rounded-lg">
            {this.state.isAuthenticated
              ? (
                <>
                  <div className="p-2 flex flex-row items-baseline">
                    <p className="text-lg font-black p-2">Posting as...</p>
                    <p className="p-1 font-semibold text-dogerblue">{this.state.username}</p>
                  </div>
                </>
              )
              : (
                  <>
                    <p className="text-lg font-bold p-3">Create Username</p>
                    <form className="mx-3" onSubmit={this.onSubmit}>
                        <input 
                          class="shadow appearance-none border rounded w-full py-2 px-3 
                            text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                          id="username" name="username" type="text" placeholder="Username" 
                          onChange={this.handleChange}
                        />
                        <div className="flex flex-row justify-end p-4">
                          <input type="submit" value="Save"
                            className="cursor-pointer bg-black px-6 py-1 rounded-full text-white font-semibold"/>
                        </div>
                    </form>
                  </>
              )
            }
          </div>
        </div>
        <div className="flex flex-col w-full h-screen overflow-y-scroll pb-24">
          <p className="p-3 text-xl font-bold">Your Feed</p>
          <div className="flex flex-col items-center ">
            <div className="my-10 rounded-lg w-4/5 border border-gray-200">
              <CreatePostPanel addPost={this.addPost} username={this.state.username}/>
            </div>
            {/* Social Feed */}
            {this.state.posts.map((post) => {
              return <span key={post.id} className="w-4/5"><Post data={post}/></span>
            })}
          </div>
        </div>
        <div className="table-cell w-1/5 align-top border-l-2 border-gray-100">
          {/* Set up profile button */}
          <Trending posts={[...this.state.posts]}/>
        </div>
      </section>
    )
  }

  render() {
    console.log(this.state.username)
    return (
      <main className="h-screen w-full overflow-hidden">
        <header className="py-3 shadow-sm bg-transparent">
          <div className="flex flex-row items-center justify-center">
            <p className="font-bold text-xl text-blue-400">Twtr</p>
          </div>
        </header>
        {this.state.isLoading
          ? this.renderLoadingState()
          : this.renderSocialFeed()}
      </main>
    )
  }
}


export default Feed;
